import React from 'react';
import { Link } from 'gatsby';

const ListLink = (props) => (
	<li style={{ display: `inline-block`, marginRight: `1rem` }}>
		<Link to={props.to}>{props.children}</Link>
	</li>
);

export default () => (
	<div style={{ color: `red` }}>
		<ul style={{ listStyle: `none`, float: `center` }}>
			<ListLink to="/">/inicio</ListLink>
			<ListLink to="/sonotrosloslocos/">son otros los locos</ListLink>
			<ListLink to="/putapoesia/">puta poesía</ListLink>
			<ListLink to="/yosoymiotroyo/">yo soy mi otro yo</ListLink>
			<ListLink to="/vuelvoavolver/">vuelvo a volver</ListLink>
		</ul>
	</div>
);
