import React from 'react';
import data from '../../gatsby-config';

export default props => (
	<div>
		<div>
			<h2 style={{ marginBottom: 0, color: `red` }}>{data.siteMetadata.title}</h2>
			<p style={{ marginTop: 0, color: `gray` }}>{data.siteMetadata.description}</p>
		</div>
		<h1 style={{ color: `gray` }}>{props.headerText}</h1>
	</div>
);
