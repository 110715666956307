module.exports = {
	siteMetadata: {
		title: 'la verba salvaje',
		description: 'de Otto Wald'
	},
	plugins: [
		'gatsby-plugin-catch-links',
		'gatsby-plugin-react-helmet',
		{
			resolve: `gatsby-plugin-typography`,
			options: {
				pathToConfigModule: `src/utils/typography`
			}
		},
		{
			resolve: `gatsby-source-filesystem`,
			options: {
				path: `src/pages`,
				name: 'pages'
			}
		},
		{
			resolve: 'gatsby-transformer-remark',
			options: {
				plugins: [] // just in case those previously mentioned remark plugins sound cool :)
			}
		}
	]
};
