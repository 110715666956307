import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Header from '../components/header';
import Navigation from '../components/navigation';
import Layout from '../components/pagelayout';

// import '../css/blog-post.css'; // make it pretty!

export default function Template({
	data // this prop will be injected by the GraphQL query we'll write in a bit
}) {
	const { markdownRemark: post } = data; // data.markdownRemark holds our post data
	return (
		<Layout>
			<Header headerText={post.frontmatter.chapter} />
			<Navigation />
			<div className="blog-post-container">
				<Helmet title={`la verba salvaje - ${post.frontmatter.title}`} />
				<div className="blog-post">
					<h2>{post.frontmatter.title}</h2>
					<div className="blog-post-content" dangerouslySetInnerHTML={{ __html: post.html }} />
					<div>
						<Link to={post.frontmatter.belongsto}>{post.frontmatter.belongsto === '' ? '' : 'volver'}</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query BlogPostByPath($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				path
				title
				belongsto
				chapter
			}
		}
	}
`;
